/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */


export type LandingPageConfiguration = {}

export type LandingPageConfigurationParam = {}

export class LandingPage {
  private static config: LandingPageConfiguration = {};

  static configure(config?: LandingPageConfigurationParam): LandingPageConfiguration {
    if (!config) {
      return LandingPage.config;
    }

    LandingPage.config = {...LandingPage.config, ...config};
    return LandingPage.config;
  }
}
