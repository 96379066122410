/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { DHLIFrame, DHLPagetemplate, trackPageCall, useAuthenticationManager, useScopeCheck } from "@gkuis/gkp-base-widgets";
import { CustomerSpecificContacts } from "@gkuis/gkp-customerspecificinformation-ui";
import { CustomerAlertContainer } from "@gkuis/gkp-notification-ui";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import "./LandingPageOverview.scss";
import { useEffect } from "react";

export type LandingPageOverviewProps = {
  cmsContentRoot: string
}

const dhlAdminRedirectUrl = "customeradministration/customeradministrationhub";
const marketingRedirectUrl = "notification/customalert/overview";
const orderRedirectUrl = "order/internal/overview";
const lqPlannerRedirectUrl = "lq/LQPlanner";

export const LandingPageOverview = observer(({
                                               cmsContentRoot
                                             }: LandingPageOverviewProps) => {

  const hasExternalScope = useScopeCheck("external") ?? false;
  const authManager = useAuthenticationManager();

  const navigate = useNavigate();

  useEffect(() => {
    if (hasExternalScope) {
      trackPageCall("GKP:Startseite");
    }
  }, [hasExternalScope]);

  useEffect(() => {
    // see https://lcm.deutschepost.de/confluence1/display/HEIDY/LandingPage for scope dependent landingpage
    authManager.getScopes().then(scopes => {
      if (scopes.includes("internal")) {
        if (scopes.includes("customer_config:customer_config_read")) {
          navigate(dhlAdminRedirectUrl);
        } else if (scopes.includes("limited_quantities:planner")) {
          navigate(lqPlannerRedirectUrl);
        } else if (scopes.includes("marketing")) {
          navigate(marketingRedirectUrl);
        } else if (scopes.includes("order:order_admin")) {
          navigate(orderRedirectUrl);
        } else if (scopes.includes("ordermanagement_mail:general")) {
          navigate("ordermanagement-mail/ordersearch");
        }
      }
    });
  }, [authManager, authManager.authenticatedSubject, navigate]);

  const landingHeaderUrl =
      cmsContentRoot + "/" + authManager.language + "/geschaeftskunden/portal/kundendesktop/intern/startseite-c6195a/teaser.data.html";

  const externalLandingPage = (
      <DHLIFrame title={"landingPage"} url={landingHeaderUrl} resizeToEventHeight={true} resizeToViewportWidth={true} height={1549} />);

  return <div className={classNames("landingpage-overview-content-container")}>
    <DHLPagetemplate className={classNames("landingpage-overview-page-template-container")}>
      <div className={classNames("landingPage-content-container")}>
        {hasExternalScope && <CustomerAlertContainer />}
        {hasExternalScope && externalLandingPage}
      </div>
    </DHLPagetemplate>
    {hasExternalScope && <CustomerSpecificContacts />}
  </div>;
});