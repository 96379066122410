/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */
import { CustomerSpecificInformationConfiguration, CustomerSpecificInformation } from "@gkuis/gkp-customerspecificinformation-ui";
import { observer } from "mobx-react-lite";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { reaction, runInAction } from "mobx";
import { AuthenticationManager } from "@gkuis/gkp-authentication";
import { LandingPage, LandingPageConfiguration } from "../../../LandingPage";
import classNames from "classnames";
import { LandingPageOverview } from "../LandingPageOverview/LandingPageOverview";
import { AuthenticationManagerContext } from "@gkuis/gkp-base-widgets";
import moment from "moment";
import { useEffect } from "react";

export type LandingPageRootProps = {
  authenticationManager: AuthenticationManager;
  landingPageConfiguration: LandingPageConfiguration;
  cmsContentRoot: string;
  csiConfig: CustomerSpecificInformationConfiguration;
}

export const LandingPageRoot = observer((
    {
      authenticationManager,
      landingPageConfiguration,
      cmsContentRoot,
      csiConfig,
    }: LandingPageRootProps
) => {
  runInAction(() => {
    CustomerSpecificInformation.configure(csiConfig);
    LandingPage.configure(landingPageConfiguration);
  });

  useEffect(() => reaction(
      () => authenticationManager.language,
      (language) => {
        moment.locale(language.startsWith("en") ? "en-gb" : "de");
      },
      {fireImmediately: true}
  ), [authenticationManager]);

  if (authenticationManager.authenticatedSubject === undefined) {
    return null;
  }

  return (
      <AuthenticationManagerContext.Provider value={authenticationManager}>
        <BrowserRouter>
          <Routes>
            <Route
                index
                element={
                  <div data-testid="landingPage" className={classNames("landingPage-container")}>
                    <LandingPageOverview cmsContentRoot={cmsContentRoot} />
                  </div>
                }
            />
          </Routes>
        </BrowserRouter>
      </AuthenticationManagerContext.Provider>
  );
});