/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */
import singleSpaReact from "single-spa-react";
import React from "react";
import ReactDOM from "react-dom";
import { LandingPageRoot } from "./components/organisms/LandingPageRoot/LandingPageRoot";
import { DHLFatalErrorPage } from "@gkuis/gkp-base-widgets";

// atoms

// molecules

// organisms

// stores

// utils
export { LandingPage } from "./LandingPage";

// types
export type { LandingPageRootProps } from "./components/organisms/LandingPageRoot/LandingPageRoot";

const reactLifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: LandingPageRoot,
  errorBoundary(err, info, props) {
    return <DHLFatalErrorPage />;
  },
});

export const bootstrap = reactLifecycles.bootstrap;
export const mount = reactLifecycles.mount;
export const unmount = reactLifecycles.unmount;
